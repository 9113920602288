
import { defineComponent } from "vue-demi";
import FAQMain from "../components/faq/index.vue";

export default defineComponent({
  name: "FAQ",
  components: {
    FAQMain,
  },
});
