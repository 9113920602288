
import { dynamicsObject } from "@/interfaces";
import { ACCESSES_ENUM, ROLES_ENUM } from "@/utils/enums";
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { Plus, Minus } from '@element-plus/icons';
import { FAQ } from "@/interfaces/dto/faq";

export default defineComponent({
  name: "FAQMain",
  data() {
    return {
      query: {
        search: ''
      },
      questions: [] as Array<FAQ.Dto>
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.emits();
  },
  unmounted() {
    this.unemits();
  },
  methods: {
    onSearch() {
      this.getData();
    },
    async getData() {
      const result = await this.API.common.getFAQList(this.query);
      this.questions = result.data;
    },
    modal(id: string, data: dynamicsObject | null) {
      this.$store.commit("createModal", { id, data });
    },
    emits() {
      this.emitter.on("createFAQ", this.createFAQEmitter);
      this.emitter.on("editFAQ", this.editFAQEmitter);
      this.emitter.on("removeFAQ", this.removeFAQEmitter);
    },
    unemits() {
      this.emitter.off("createFAQ", this.createFAQEmitter);
      this.emitter.off("editFAQ", this.editFAQEmitter);
      this.emitter.off("removeFAQ", this.removeFAQEmitter);
    },
    createFAQEmitter(data: FAQ.Dto) {
      this.questions = [...this.questions, data];
    },
    editFAQEmitter(data: FAQ.Dto) {
      const faq = this.questions.find(q => String(q._id) === String(data._id));
      if (faq) {
        faq.question = data.question;
        faq.answer = data.answer;
      }
    },
    removeFAQEmitter(data: string) {
      this.questions = this.questions.filter(q => String(q._id) !== String(data));
    }
  },
  computed: {
    ...mapGetters(['user']),
    staff() {
      const user = this.user as dynamicsObject;
      return user && (user.role === ROLES_ENUM.OWNER || user.accesses.includes(ACCESSES_ENUM.EXPERT));
    }
  },
  components: {
    Plus, Minus
  }
});
